import "./apps/navigation";
import "../website/apps/footer.js";

import home from "./apps/home";
import listings from "./apps/listings";
import about from "./apps/about";
import minimal from "../website/apps/minimal";

import { provisionApp, registerGlobalComponents } from "../Vue/Global";
const requireContextComponents = require.context('../components', true, /\.vue$/i);
const requireContextWebsiteComponents = require.context('../website/components', true, /\.vue$/i);
const requireContextAgentsiteComponents = require.context('./components', false, /\.vue$/i);

const app = $app.register({
    home,
    listings,
    about,
    minimal,
}).createApp();

provisionApp(app);

registerGlobalComponents(app, [
    requireContextComponents,
    requireContextWebsiteComponents,
    requireContextAgentsiteComponents,
]);

app.mount('#app');
