import Api from "../../services/Api";
import Alert from "../../services/Alert";
import { createApp } from "vue";

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                showLoader: false,
            });
        },

        methods: {
            showMoreListings() {
                let listingUrl;
                let routePrefix = appData.ownerType === 'team' ? 'teams' : 'agents';
                this.showLoader = true;

                if (appData.pageName === 'Active Listings') {
                    listingUrl =  `/${routePrefix}/${appData.ownerID}/listings/active?offset=${appData.listings.length}&limit=10&isAgentsite`;
                } else {
                    listingUrl = `/${routePrefix}/${appData.ownerID}/listings/recent?offset=${appData.listings.length}&limit=10&isAgentsite`;
                }

                Api.get(listingUrl, {})
                    .then(response => appData.listings = appData.listings.concat(response.data))
                    .catch(err => Alert.error(err))
                    .finally(() => this.showLoader = false);
            }
        },
    })
}
