import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                modal: {
                    title: null,
                    videoUrl: null,
                    videoTagline: null,
                },
            });
        },

        computed: {
            heroIsVideo() {
                return this.homepageHero.type === 'video';
            },
            heroStyleObj() {
                return this.heroIsVideo
                    ? {}
                    : {
                        'backgroundImage': `linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(${this.homepageHero.src})`,
                        'backgroundColor': 'black',
                    };
            },
            sourceType() {
                return this.heroIsVideo ? `video/${this.homepageHero.extension}` : null;
            },
        },

        methods: {
            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.title = modalData.title;
                this.modal.videoTagline = modalData.description;
            },
            openContactModal() {
                this.$modal.show('contact-modal');
            },
        },
    })
}
