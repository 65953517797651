import { createApp } from "vue";
import SvgVue from 'svg-vue3';
import ModalPlugin from "../../Vue/ModalPlugin";
import VueClickAway from "vue3-click-away";
import VIcon from "../../components/VIcon";


const app = createApp({
    data() {
        return {
            logos: {},
            dropdown: null,
            navVisible: false,
            preventMenuClose: false,
            isHomepage: false,
            scrollY: 0,
        };
    },

    computed: {
        headerLogo() {
            return this.isTransparent ? this.logos['darkMode'] : this.logos['default'];
        },
        mobileHeaderLogo() {
            return this.logos['default'];
        },
        isTransparent() {
            return this.isHomepage && this.scrollY < 1;
        },
    },

    methods: {
        showDropdown(dropdown, toggle = false) {
            if (toggle && this.dropdown === dropdown) {
                return this.preventMenuClose ? null : this.hideDropdown();
            }

            this.dropdown = dropdown;

            this.preventMenuClose = true;
            setTimeout(() => {
                this.preventMenuClose = false;
            }, 500);
        },
        hideDropdown() {
            this.dropdown = null;
        },
        toggleNav() {
            this.navVisible = !this.navVisible;
        },
        onScroll() {
            this.scrollY = window.scrollY;
        },
        openContactModal() {
            this.$modal.show('contact-modal');
        },
        closeContactModal() {
            this.$modal.hide('contact-modal');
        },
    },

    mounted() {
        this.debouncedOnScroll = _.debounce(this.onScroll, 25);
        window.addEventListener('scroll', this.debouncedOnScroll);

        this.logos = window.$logos;
        this.isHomepage = window.$isHomepage;
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.debouncedOnScroll);
    },
})

app.use(SvgVue);
app.use(ModalPlugin);
app.use(VueClickAway);
app.component('VIcon', VIcon);

app.mount('#headerapp');
