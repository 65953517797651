import { createApp } from 'vue';

export default function (appData) {
    return createApp({
        data() {
            return _.merge(appData, {
                modal: {
                    title: null,
                    videoUrl: null,
                    videoTagline: null,
                },
            })
        },

        methods: {
            initVideoModal(modalData) {
                this.modal.videoUrl = modalData.url;
                this.modal.title = modalData.title;
                this.modal.videoTagline = modalData.description;
            },
            
            openContactModal() {
                this.$modal.show('contact-modal');
            },
        },
    })
}
